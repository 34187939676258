import './App.css';
import React from "react";
import Greeting from './components/greeting/greeting';
import Navigation from './components/navigation/navigation'
//import Login from './components/login/login'
import Kubernetes from './components/kubernetes/kubernetes'

import Cookies from 'js-cookie'
//import FxChart from './components/charts/investment/fx/fx-chart';

export const getAccessToken = () => Cookies.get('access_token')
export const getRefreshToken = () => Cookies.get('refresh_token')
export const isAuthenticated = () => !!getAccessToken()



export default function App() {
  return (
    <div className="App">
      <Greeting></Greeting>

    </div>
  );
}


import React from "react";
import Carousel from "react-material-ui-carousel";
import { Paper } from "@mui/material";

class Item extends React.Component {
  render() {
    if (this.props.item.image.includes(".mp4"))
      return (
        <Paper>
          <h2>{this.props.item.name}</h2>
          <p>{this.props.item.description}</p>
          <video
            src={this.props.item.image}
            alt={this.props.item.description}
          />
        </Paper>
      );

    return (
      <Paper>
        <h2>{this.props.item.name}</h2>
        <p>{this.props.item.description}</p>
        <img src={this.props.item.image} alt={this.props.item.description} />
      </Paper>
    );
  }
}

class Greeting extends React.Component {
  render() {
    var items = [
      {
        name: "Merhaba",
        description: "Hayat güzel",
        image: "/Papatya.png",
      },
      {
        name: "Bafa",
        description: "Bafa'dan bir Beşparmak (Latmos) dağları fotoğrafı",
        image: "/Bafa.png",
      },
      {
        name: "Motorsiklet gezilerim",
        description: "Didim' in güzel bir koyunda gün batımı",
        image: "/Motorsiklet.png",
      },
      {
        name: "Tekne gezilerim",
        description: "Alaçatı körfezinde tekne seyri",
        image: "/gunes-can.png",
      },
      {
        name: "Milli Parklar ve Koruma Alanları",
        description: "Doğanbey Milli Parkı",
        image: "/Doganbey-MilliPark.png",
      },
      {
        name: "Milli Parklar ve Koruma Alanları",
        description: "Balat",
        image: "/Balat.png",
      },
      {
        name: "Bahçe",
        description: "Ceviz ağacı fidanı",
        image: "/Bafa-CevizAgaci.png",
      },
      {
        name: "Sualtı",
        description: "Altınkum plajından sualtı görüntüleri",
        image: "/Underwater-Altinkum.mp4",
      },
    ];
    return (
      <div>
        <h1>Hoş geldin!</h1>
        <Carousel>
          {items.map((item, i) => (
            <Item key={i} item={item} />
          ))}
        </Carousel>
      </div>
    );
  }
}

export default Greeting;

import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
//import reportWebVitals from './reportWebVitals';
//**Firebase */
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCHZQYFg2BVF3ZnQCbKl4ECd2GxC2ijTcw",
  authDomain: "deft-beacon-214917.firebaseapp.com",
  databaseURL: "https://deft-beacon-214917.firebaseio.com",
  projectId: "deft-beacon-214917",
  storageBucket: "deft-beacon-214917.appspot.com",
  messagingSenderId: "773446451866",
  appId: "1:773446451866:web:5b9df18ec1a8d87974d523"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
//** Firebase end */

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();

import React from "react";
import { Accordion, AccordionSummary, AccordionDetails, Typography, List, ListItem, Box, Card, CardActionArea, CardHeader, CardContent } from "@mui/material";
import { styled } from '@mui/material/styles';
import Grid from '@mui/material/Grid'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

class Navigation extends React.Component {

    render() {
        const Item = styled(Card)(({ theme }) => ({
            ...theme.typography.body2,
            padding: theme.spacing(1),
            textAlign: 'center',
            color: theme.palette.text.secondary,
        }));
        return (
            <Box sx={[{maxWidth: '1600px'}, {margin: 'auto' }]} >
                <Grid container spacing={2} >
                    <Grid item xs={12} sm={6} lg={4} xl={3} >
                        <Item>
                            <CardActionArea>
                                <CardHeader title='Integration Development'></CardHeader>
                                <CardContent>
                                    <Accordion>
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls="panel1a-content"
                                            id="panel1a-header"
                                        >
                                            <Typography>Enterprise Integration is a software engineering area to transfer data between end point applications. </Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <List>
                                                <ListItem>
                                                    <Accordion sx={{width: "100%" }}>
                                                        <AccordionSummary
                                                            expandIcon={<ExpandMoreIcon />}
                                                            aria-controls="panel1a-content"
                                                            id="panel1a-header"
                                                        >
                                                            <Typography>Enterprise Application Integration</Typography>
                                                        </AccordionSummary>
                                                        <AccordionDetails>
                                                            <List>
                                                                <ListItem><a href='https://www.ibm.com/docs/en/integration-bus/10.0?topic=overview-integration-bus-introduction' target='_blank' rel="noopener noreferrer">IBM Integration Bus</a></ListItem>
                                                                <ListItem><a href='https://docs.oracle.com/en-us/iaas/integration/index.html' target='_blank' rel="noopener noreferrer">Oracle Integration Cloud Services</a></ListItem>
                                                                <ListItem><a href='https://spring.io/projects/spring-integration'  target='_blank' rel='noopener noreferrer'>Spring Integration</a></ListItem>
                                                                <ListItem><a href='https://www.enterpriseintegrationpatterns.com/patterns/messaging/'  target='_blank' rel='noopener noreferrer'>Messaging Patterns</a></ListItem>
                                                                <ListItem><a href='https://activemq.apache.org/' target='_blank' rel='noopener noreferrer'>Apache ActiveMQ</a></ListItem>
                                                                <ListItem><a href='https://martinfowler.com/articles/microservices.html' target='_blank' rel='noopener noreferrer' >Microservices Architecture</a></ListItem>
                                                                <ListItem>Streaming</ListItem>
                                                                <ListItem>Apache Kafka</ListItem>
                                                            </List>
                                                        </AccordionDetails>
                                                    </Accordion>
                                                </ListItem>
                                                <ListItem>
                                                    <Accordion sx={{width: "100%" }} >
                                                        <AccordionSummary
                                                            expandIcon={<ExpandMoreIcon />}
                                                            aria-controls="panel1a-content"
                                                            id="panel1a-header"
                                                        >
                                                            <Typography>Data Integration</Typography>
                                                        </AccordionSummary>
                                                        <AccordionDetails>
                                                            <List>
                                                                <ListItem><a href='https://www.ibm.com/docs/en/integration-bus/10.0?topic=overview-integration-bus-introduction' target='_blank' rel="noopener noreferrer">IBM Integration Bus</a></ListItem>
                                                                <ListItem><a href='https://www.oracle.com/tr/middleware/technologies/data-integrator.html' target='_blank' rel="noopener noreferrer">Oracle Data Integrator</a></ListItem>
                                                                <ListItem><a href='https://nifi.apache.org/' target='_blank' rel="noopener noreferrer">Apache Nifi</a></ListItem>
                                                                <ListItem>ETL</ListItem>
                                                            </List>
                                                        </AccordionDetails>
                                                    </Accordion>
                                                </ListItem>
                                            </List>
                                        </AccordionDetails>
                                    </Accordion>
                                </CardContent>
                            </CardActionArea>

                        </Item>
                    </Grid>
                    <Grid item xs={12} sm={6} lg={4} xl={3} >
                        <Item>
                            <CardActionArea>
                                <CardHeader title='Information Science and Computer Engineering'></CardHeader>
                                <CardContent>Software Architecture, Object Oriented Design, Development Process, Quality Assurence, Maintenance  </CardContent>
                            </CardActionArea>
                        </Item>
                    </Grid>
                    <Grid item xs={12} sm={6} lg={4} xl={3} >
                        <Item>
                            <CardActionArea>
                                <CardHeader title='Team Building'></CardHeader>
                                <CardContent>Specify a job description, evaluate applications and resumes, interview and evaluation process</CardContent>
                            </CardActionArea>
                        </Item>
                    </Grid>
                    <Grid item xs={12} sm={6} lg={4} xl={3} >
                        <Item>
                            <CardActionArea>
                                <CardHeader title='Training'></CardHeader>
                                <CardContent>Identifying training needs, finding providers, certification or not, monitoring progress </CardContent>
                            </CardActionArea>
                        </Item>
                    </Grid>
                    <Grid item xs={12} sm={6} lg={4} xl={3} >
                        <Item>
                            <CardActionArea>
                                <CardHeader title='Sports'></CardHeader>
                                <CardContent>A healthy addiction for sailing, cycling, swimming, diving </CardContent>
                            </CardActionArea>
                        </Item>
                    </Grid>
                    <Grid item xs={12} sm={6} lg={4} xl={3} >
                        <Item>
                            <CardActionArea>
                                <CardHeader title='Gardening'></CardHeader>
                                <CardContent>Enjoying growing flowers at home</CardContent>
                            </CardActionArea>
                        </Item>
                    </Grid>
                    <Grid item xs={12} sm={6} lg={4} xl={3} >
                        <Item>
                            <CardActionArea>
                                <CardHeader title='Game Development'></CardHeader>
                                <CardContent>Hobby </CardContent>
                            </CardActionArea>
                        </Item>
                    </Grid>
                    <Grid item xs={12} sm={6} lg={4} xl={3} >
                        <Item>
                            <CardActionArea>
                                <CardHeader title='Father'></CardHeader>
                                <CardContent>Father of Güneş and İsmet Can</CardContent>
                            </CardActionArea>
                        </Item>
                    </Grid>
                </Grid>
            </Box >
        );
    }
}

export default Navigation;


import React from "react";

export default class Kubernetes extends React.Component {
  render() {
    return (
      <div class="left-aligned">
        <h1>Kubernetes</h1>
        <h2 class="indent-1">kubectl command</h2>
        <ul class="indent-2">
          <li>
            <h3>Basic Commands (Beginner)</h3>
            <table>
              <tr>
                <td>create</td>
                <td>Create a resource from a file or from stdin</td>
              </tr>
              <tr>
                <td>expose</td>
                <td>
                  Take a replication controller, service, deployment or pod and
                  expose it as a new Kubernetes service
                </td>
              </tr>
              <tr>
                <td>run</td>
                <td>Run a particular image on the cluster</td>
              </tr>
              <tr>
                <td>set</td>
                <td>Set specific features on objects</td>
              </tr>
            </table>
          </li>
          <li>
            <h3>Basic Commands (Intermediate)</h3>
            <table>
              <tr>
                <td>explain</td>
                <td>Get documentation for a resource</td>
              </tr>
              <tr>
                <td>get</td>
                <td>Display one or many resources</td>
              </tr>
              <tr>
                <td>edit</td>
                <td>Edit a resource on the server</td>
              </tr>
              <tr>
                <td>delete</td>
                <td>
                  Delete resources by file names, stdin, resources and names, or
                  by resources and label selector
                </td>
              </tr>
            </table>
          </li>
          <li>
            <h3>Deploy Commands</h3>
            <table>
              <tr>
                <td>rollout</td>
                <td>Manage the rollout of a resource</td>
              </tr>
              <tr>
                <td>scale</td>
                <td>
                  Set a new size for a deployment, replica set, or replication
                  controller
                </td>
              </tr>
              <tr>
                <td>autoscale</td>
                <td>
                  Auto-scale a deployment, replica set, stateful set, or
                  replication controller
                </td>
              </tr>
            </table>
          </li>
          <li>
            <h3>Cluster Management Commands</h3>
            <table>
              <tr>
                <td>certificate </td>
                <td>Modify certificate resources.</td>
              </tr>
              <tr>
                <td>cluster-info</td>
                <td>Display cluster information</td>
              </tr>
              <tr>
                <td>top </td>
                <td>Display resource (CPU/memory) usage</td>
              </tr>
              <tr>
                <td>cordon </td>
                <td>Mark node as unschedulable</td>
              </tr>
              <tr>
                <td>uncordon </td>
                <td>Mark node as schedulable</td>
              </tr>
              <tr>
                <td>drain </td>
                <td>Drain node in preparation for maintenance</td>
              </tr>
              <tr>
                <td>taint </td>
                <td>Update the taints on one or more nodes</td>
              </tr>
            </table>
          </li>
        </ul>
      </div>
    );
  }
}
